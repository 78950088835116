<template>
  <div id="vehicle-overview">
    <v-tabs v-model="currentTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>
          {{ tab.title }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <h2 class="text-left text-xl font-weight-semibold label">Device Enabled</h2>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-switch
                  v-model="vehicle_settings.is_enabled"
                  label="Device enabled"
                  class="v-input--reverse"
                  hide-details
                  :disabled="!canConfig"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <h2 class="text-left text-xl font-weight-semibold label">Vehicle Operator</h2>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-select
                  v-model="vehicle_settings.operator"
                  :items="operatorItems"
                  item-text="text"
                  item-value="value"
                  label="Operator"
                  outlined
                  hide-details
                  dense
                  :disabled="!canConfig"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2 class="text-left text-xl font-weight-semibold label">Volume Settings</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  v-model="vehicle_settings.override_volume"
                  label="Override default volume"
                  class="v-input--reverse"
                  hide-details
                  :disabled="!canConfig"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div v-if="vehicle_settings.override_volume">
          <v-card flat>
            <v-card-text>
              <v-divider class="mb-4"></v-divider>
              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Custom volume</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>Peak volume</span>
                </v-col>
                <v-col cols="1">
                  <span class="font-weight-medium me-2">
                    <v-icon class="me-1" size="18" @click="vehicle_settings.peak_volume = 0">
                      {{ vehicle_settings.peak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                    </v-icon>
                  </span>
                </v-col>
                <v-col cols="8">
                  <v-slider
                    v-model="vehicle_settings.peak_volume"
                    color="primary"
                    thumb-label="always"
                    :disabled="!canConfig"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>Off-peak volume</span>
                </v-col>
                <v-col cols="1">
                  <span class="font-weight-medium me-2">
                    <v-icon class="me-1" size="18" @click="vehicle_settings.offpeak_volume = 0">
                      {{ vehicle_settings.offpeak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                    </v-icon>
                  </span>
                </v-col>
                <v-col cols="8">
                  <v-slider
                    v-model="vehicle_settings.offpeak_volume"
                    color="primary"
                    thumb-label="always"
                    :disabled="!canConfig"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <span>Quiet volume</span>
                </v-col>
                <v-col cols="1">
                  <span class="font-weight-medium me-2">
                    <v-icon class="me-1" size="18" @click="vehicle_settings.quiet_volume = 0">
                      {{ vehicle_settings.quiet_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                    </v-icon>
                  </span>
                </v-col>
                <v-col cols="8">
                  <v-slider
                    v-model="vehicle_settings.quiet_volume"
                    color="primary"
                    thumb-label="always"
                    :disabled="!canConfig"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Volume Notes</h2>
                  <v-textarea v-model="vehicle_settings.notes" :disabled="!canConfig" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>

      <v-tab-item v-if="canNotes">
        <v-card>
          <!-- Notes Filter fields start -->
          <v-row class="pt-4 pb-0 ma-0">
            <v-col cols="12" sm="4">
              <v-select
                v-model="sqPriority"
                :items="priorityItems"
                item-text="text"
                item-value="value"
                label="Priority"
                placeholder="Priority"
                outlined
                hide-details
                clearable
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqPriority')"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="sqClaimant"
                label="Claimant"
                outlined
                hide-details
                clearable
                persistent-clear
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqClaimant')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sqCreated"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    outlined
                    hide-details
                    dense
                    clearable
                    class="user-search me-3"
                    v-on="on"
                    @change="val => addToSearchHistory(val, 'sqCreated')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="sqCreated" no-title @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Notes Filter fields end -->
          <!-- Notes Table start -->
          <v-data-table
            :headers="noteHeaders"
            :items="filteredNotes"
            :sort-by.sync="noteSortBy"
            :sort-desc.sync="noteSortDesc"
            :custom-sort="customSort"
            class="font-weight-semibold text--primary"
            :must-sort="true"
          >
            <template v-slot:item.note_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.note_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.title="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.title }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.priority="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.priority }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.status="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.status }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.claimant="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.claimant }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.convertCreatedDate="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span class="font-weight-semibold text--primary" v-html="convertCreatedDate(item)"></span>
                  </div>
                </div>
              </router-link>
            </template>
          </v-data-table>
          <!-- Notes Table end -->
          <!-- Add Note Button, Modal and Form start -->
          <v-row v-if="device" class="pt-8 pl-5 pb-6 ma-0">
            <v-btn color="primary" elevation="0" @click="openDialog">Add Note</v-btn>
          </v-row>
          <v-row v-else align="center" class="pt-8 pl-5 pb-6 ma-0">
            <v-btn color="primary" elevation="0" disabled class="mr-4" @click="openDialog">Add Note</v-btn>
            <v-chip outlined>A device needs to be assigned to a vehicle before adding a note</v-chip>
          </v-row>
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="d-flex">
                <span class="headline flex-grow-1">Add Note</span>
                <v-btn color="secondary" outlined elevation="0" @click="closeDialog">Close</v-btn>
              </v-card-title>
              <v-card-text>
                <v-form lazy-validation @submit.prevent="() => {}">
                  <v-text-field v-model="formData.title" label="Title *"></v-text-field>
                  <v-textarea v-model="formData.comments" label="Comments *"></v-textarea>
                  <v-select v-model="formData.selectedPriority" :items="priorityOptions" label="Select Priority *">
                  </v-select>
                  <v-select v-model="formData.selectedStatus" :items="statusOptions" label="Select Status *"></v-select>
                  <v-text-field v-model="formData.claimant" label="Claimant *"></v-text-field>
                  <v-text-field v-model="formData.assigned" label="Assigned To (optional)"></v-text-field>
                  <v-text-field v-model="formData.location" label="Location (optional)"></v-text-field>
                  <v-btn class="mt-7" color="primary" :disabled="note_cannotSave" @click="addNote()"> Add </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Add Note Button, Modal and Form end -->
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="canConfig">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h2 class="text-left text-xl font-weight-semibold label">Audit</h2>
              </v-col>
            </v-row>
            <v-row v-if="!isLoading">
              <v-col>
                <v-data-table :key="tableKey" :items="auditLogs" :headers="auditLogTableHeaders"></v-data-table>
              </v-col>
            </v-row>
            <v-row v-else class="inline-loader">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="canCommands || canProvision">
        <v-card v-if="canCommands" class="mb-4">
          <v-card-text>
            <v-row v-for="command in commands" :key="command.command">
              <v-col cols="4" class="text-uppercase font-weight-bold d-flex align-center">
                {{ command.title }}
                <v-icon class="ml-1">
                  {{ command.icon }}
                </v-icon>
              </v-col>
              <v-col cols="5" class="font-weight-medium d-flex align-center">
                {{ command.declaration }}
              </v-col>
              <v-col cols="1" class="d-flex align-center">
                <v-dialog v-model="command.isDialogVisible" persistent width="500">
                  <template #activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      {{ command.buttonTitle }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline"> Execute the command: {{ command.command }}? </v-card-title>
                    <v-card-text> {{ command.warning }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" outlined @click="closeCommandDialog(command)"> Cancel </v-btn>
                      <v-btn color="primary" @click="executeCommand(command, true)"> Go </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="canProvision" class="mb-4">
          <v-card-text>
            <v-row v-for="provision in provisioning" :key="provision.provision" justify="space-between">
              <v-col cols="3" class="text-uppercase font-weight-bold d-flex align-center">
                {{ provision.title }}
                <v-icon class="ml-1">
                  {{ provision.icon }}
                </v-icon>
              </v-col>
              <v-col cols="3" class="d-flex align-center">
                <v-dialog v-model="provision.isDialogVisible" persistent width="500">
                  <template #activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" :disabled="!vehicle.status.device_id" v-on="on">
                      {{ provision.command }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline"> {{ provision.title }}? </v-card-title>
                    <v-card-text> {{ provision.warning }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" outlined @click="closeCommandDialog(provision)"> Cancel </v-btn>
                      <v-btn color="primary" @click="execute(provision, true)"> Go </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="canDiagnose">
          <v-card-title class="headline">Diagnostics</v-card-title>
          <v-card-text>
            <v-row v-for="diagnosis in diagnoses" :key="diagnosis.title" class="mb-5" justify="space-between">
              <v-col cols="3" class="text-uppercase font-weight-bold d-flex align-center">
                {{ diagnosis.title }}
                <v-icon class="ml-1">
                  {{ diagnosis.icon }}
                </v-icon>
              </v-col>
              <v-col cols="3" class="d-flex align-center">
                <v-dialog v-model="diagnosis.isDialogVisible" persistent width="500">
                  <template #activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" :disabled="!vehicle.status.device_id" v-on="on">
                      {{ diagnosis.command }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline"> {{ diagnosis.title }}? </v-card-title>
                    <v-card-text> {{ diagnosis.warning }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" outlined @click="closeCommandDialog(diagnosis)"> Cancel </v-btn>
                      <v-btn color="primary" @click="execute(diagnosis, true)"> Go </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row v-if="diagnosticLogData.length">
              <h3 class="ml-3 mb-3">Recent device events:</h3>
              <v-card v-for="(logEntry, index) in diagnosticLogData" :key="`log-${index}`">
                <v-card-title>
                  {{ formatTimestamp(logEntry.timestamp) }}
                </v-card-title>
                <v-card-text>
                  {{ logEntry }}
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <VehicleGPSHistory :vehicle="vehicle" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  mdiVolumeOff,
  mdiVolumeHigh,
  mdiVolumeMedium,
  mdiReload,
  mdiRouterWirelessSettings,
  mdiPowerCycle,
  mdiGauge,
  mdiDelete,
  mdiMessagePlus,
  mdiHistory,
  mdiTextBoxMultipleOutline,
  mdiCalendar,
} from "@mdi/js"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import { mapState } from "vuex"
import store from "@/store"
import VehicleGPSHistory from "./VehicleGPSHistory.vue"
import urlHelpers from "@/mixins/urlHelpers"
import globalHelpers from "@/mixins/globalHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "VehicleOverview",
  components: {
    VehicleGPSHistory,
  },
  mixins: [urlHelpers, globalHelpers],
  data() {
    return {
      vehicle_settings: {},
      currentTab: "",
      minDate: "", // Initialize minDate
      maxDate: "", // Initialize maxDate
      operatorItems: ["", "Kinetic", "TranzUrban", "Mana", "Uzabus"],
      commands: [
        // Only restart implemented currently
        {
          title: "Restart",
          buttonTitle: "Restart",
          command: "safe_restart",
          icon: mdiReload,
          declaration:
            "The command will refresh the application of the current vehicle's onboard device without power cycling",
          warning:
            "This command will take several seconds to run. The vehicle application will not function during this time.",
          isDialogVisible: false,
        },
        {
          title: "Reboot",
          buttonTitle: "Reboot",
          command: "reboot",
          icon: mdiPowerCycle,
          declaration: "The command will power cycle the current vehicle's onboard device.",
          warning:
            "This command will shut down and restart the device. The vehicle application will not function for several seconds during this time.",
          isDialogVisible: false,
        },
        {
          title: "Peak Audio",
          buttonTitle: "Test",
          command: "test_audio_peak",
          icon: mdiVolumeHigh,
          declaration: "The command will play a test audio file at peak volume on the onboard device.",
          warning: "This command will play a test audio clip even if the trip is running.",
          isDialogVisible: false,
        },
        {
          title: "Off-Peak Audio",
          buttonTitle: "Test",
          command: "test_audio_offpeak",
          icon: mdiVolumeMedium,
          declaration: "The command will play a test audio file at off-peak volume on the onboard device.",
          warning: "This command will play a test audio clip even if the trip is running.",
          isDialogVisible: false,
        },
        {
          title: "Quiet Audio",
          buttonTitle: "Test",
          command: "test_audio_quiet",
          icon: mdiVolumeMedium,
          declaration: "The command will play a test audio file at quiet volume on the onboard device.",
          warning: "This command will play a test audio clip even if the trip is running.",
          isDialogVisible: false,
        },
      ],
      provisioning: [
        {
          command: "decommission",
          title: "Decommission Device",
          icon: mdiDelete,
          warning: "This command decommissions the device.",
          isDialogVisible: false,
          action: () => {
            this.decommissionDevice()
          },
        },
      ],
      diagnoses: [
        {
          command: "test",
          title: "Level 1 test",
          icon: mdiGauge,
          warning: "This command will play a test audio clip even if the trip is running.",
          isDialogVisible: false,
          action: () => {
            this.runDiagnostic()
          },
        },
      ],
      sqCreated: "",
      sqClaimant: "",
      sqPriority: null,
      noteSortBy: "note_id",
      menu: false,
      // Notes Table Headers
      noteHeaders: [
        { text: "Note ID", value: "note_id" },
        { text: "Title", value: "title" },
        { text: "Priority", value: "priority" },
        { text: "Status", value: "status" },
        { text: "Claimant", value: "claimant" },
        { text: "Created", value: "convertCreatedDate" },
      ],
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      formData: {
        selectedType: "Device",
        selectedForeignId: null,
        title: "",
        comments: "",
        selectedPriority: null,
        selectedStatus: null,
        claimant: "",
        assigned: "",
        location: "",
      },
      diagnosticLogData: [],
      dialog: false,
      auditLogTableHeaders: [
        {
          text: "User",
          value: "user",
          sortable: true,
        },
        {
          text: "Timestamp",
          value: "timestamp",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: true,
        },
        {
          text: "Is enabled",
          value: "is_enabled",
          sortable: false,
        },
        {
          text: "Override volume",
          value: "override_volume",
          sortable: false,
        },
        {
          text: "Notes",
          value: "notes",
          sortable: false,
        },
        {
          text: "Peak vol",
          value: "peak_volume",
          sortable: false,
        },
        {
          text: "Off-peak vol",
          value: "offpeak_volume",
          sortable: false,
        },
        {
          text: "Quiet vol",
          value: "quiet_volume",
          sortable: false,
        },
      ],
      auditLogs: [],
      tableKey: 0,
      isLoading: false,
      noteSortDesc: false, // true for descending order, false for ascending order
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      vehicle: state => state.selected_vehicle,
      devices: state => state.gtfs.devices,
      notes: state => state.gtfs.notes,
    }),
    tabs() {
      const tabs = []

      // NOTE: you cannot use the same icon twice!
      tabs.push({ title: "Configuration", icon: mdiMessagePlus })

      if (this.canNotes) {
        tabs.push({ title: "Notes", icon: mdiTextBoxMultipleOutline })
      }

      if (this.canConfig) {
        tabs.push({ title: "Audit", icon: mdiCalendar })
      }

      if (this.canCommands || this.canProvision || this.canDiagnose) {
        tabs.push({ title: "Commands", icon: mdiRouterWirelessSettings })
      }

      tabs.push({ title: "GPS History", icon: mdiHistory })

      return tabs
    },
    canConfig() {
      return this.$auth.hasScope("vehicles:edit")
    },
    canCommands() {
      return this.$auth.hasScope("vehicles:command")
    },
    canProvision() {
      return this.$auth.hasScope("provision")
    },
    canDiagnose() {
      return this.$auth.hasScope("vehicles:diagnostic")
    },
    canNotes() {
      return this.$auth.hasScope("notes") || this.$auth.hasScope("vehicles:notes")
    },
    device() {
      return this.devices.find(device => device.config.vehicle_id === this.vehicle.vehicle_id) || null
    },
    // Notes Filtering
    filteredNotes() {
      if (!this.device) {
        return []
      }

      return this.notes.filter(note => {
        let include = true
        if (include && this.sqCreated !== "" && this.sqCreated !== null) {
          // eslint-disable-next-line no-undef
          const date = dayjs(this.formatDate(note.created, "en-NZ"), "D/MM/YYYY, h:mm:ss a")
          include = date.format("YYYY-MM-DD") === this.sqCreated
        }
        if (include && this.sqClaimant !== "" && this.sqClaimant !== null) {
          include = note.claimant.toLowerCase().includes(this.sqClaimant.toLowerCase())
        }
        if (include && this.sqPriority !== "" && this.sqPriority !== null) {
          include = note.priority === this.sqPriority
        }

        return include
      })
    },
    convertCreatedDate() {
      // eslint-disable-next-line func-names
      return function (item) {
        return item.created ? this.formatDate(item.created, "en-NZ") : "Unknown"
      }
    },
    priorityOptions() {
      return ["Low", "Medium", "High", "Urgent"]
    },
    statusOptions() {
      return ["Open", "Pending", "On Hold", "Closed"]
    },
    note_cannotSave() {
      return (
        !this.formData.title ||
        !this.formData.comments ||
        !this.formData.selectedPriority ||
        !this.formData.selectedStatus ||
        !this.formData.claimant
      )
    },
  },
  created() {
    this.vehicle_settings = JSON.parse(JSON.stringify(this.vehicle.config))

    if (this.vehicle_settings.peak_volume == null) {
      this.vehicle_settings.peak_volume = this.settings.peak_volume
    }

    if (this.vehicle_settings.offpeak_volume == null) {
      this.vehicle_settings.offpeak_volume = this.settings.offpeak_volume
    }

    if (this.vehicle_settings.quiet_volume == null) {
      this.vehicle_settings.quiet_volume = this.settings.quiet_volume
    }

    if (this.vehicle_settings.notes == null) {
      this.vehicle_settings.notes = ""
    }

    if (this.vehicle_settings.operator == null) {
      this.vehicle_settings.operator = ""
    }

    this.fetchAuditLogs()

    // Only get notes if a device is assigned to the vehicle
    if (this.device) {
      this.formData.selectedForeignId = {
        title: this.getDeviceAndVehicleTitle(),
        value: this.device.device_id,
      }

      store.dispatch("getNotes", {
        type: "Device",
        foreign_id: this.device.device_id,
      })
    }
  },
  methods: {
    async fetchAuditLogs() {
      const data = await store.dispatch("getAuditLogs", {
        type: "vehicle",
        id: this.vehicle.vehicle_id,
      })

      this.auditLogs = data.map(item => {
        const messageData = JSON.parse(item.message)

        if (!messageData.action && messageData?.url?.split("/").pop().startsWith("command")) {
          messageData.action = messageData?.url?.split("/").pop()
        } else if (!messageData.action) {
          messageData.action = "configuration"
        }

        return {
          ...item,
          ...messageData,
        }
      })
    },

    /**
     * Overrides default sorting by table headers.
     */
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = null
        let sortB = null

        // Handle sorting based on clicked header column
        switch (sortByField) {
          case "convertCreatedDate": {
            // We don't want to use convertCreatedDate as it can return "Unknown"
            sortA = new Date(a?.created)
            sortB = new Date(b?.created)

            break
          }
          case "priority": {
            // Order by priority not alphabetically
            const priorityOrder = {
              urgent: 1,
              high: 2,
              medium: 3,
              low: 4,
            }

            sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
            sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

            break
          }
          default: {
            // Default sorting
            sortA = a[sortBy]
            sortB = b[sortBy]

            break
          }
        }

        if (sortA < sortB) {
          return -1
        }

        if (sortA > sortB) {
          return 1
        }

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
    formatTimestamp(timestamp) {
      return dayjs.unix(timestamp).tz("Pacific/Auckland").format("YYYY-MM-DD HH:mm")
    },
    resetConfiguration() {
      this.vehicle_settings = {
        peak_volume: this.settings.peak_volume,
        offpeak_volume: this.settings.offpeak_volume,
        quiet_volume: this.settings.quiet_volume,
      }
    },
    saveVehicleSettings() {
      if (this.canConfig) {
        store.dispatch("saveVehicleConfig", this.vehicle_settings)
      }
    },
    closeCommandDialog(command) {
      command.isDialogVisible = false // eslint-disable-line no-param-reassign
    },
    execute(command) {
      command.isDialogVisible = false // eslint-disable-line no-param-reassign
      if (command.action) {
        command.action()
      }
    },
    executeCommand(command) {
      if (this.canCommands) {
        command.isDialogVisible = false // eslint-disable-line no-param-reassign

        let device_command = command.command

        // If test audio commands, set volume to the appropriate level based on whether global override
        if (command.command.startsWith("test_audio")) {
          switch (command.command) {
            case "test_audio_peak":
              command.volume = this.vehicle_settings.override_volume
                ? this.vehicle_settings.peak_volume
                : this.settings.peak_volume
              break
            case "test_audio_quiet":
              command.volume = this.vehicle_settings.override_volume
                ? this.vehicle_settings.quiet_volume
                : this.settings.quiet_volume
              break
            case "test_audio_offpeak":
            default:
              command.volume = this.vehicle_settings.override_volume
                ? this.vehicle_settings.offpeak_volume
                : this.settings.offpeak_volume
          }
          // remove the test_audio version suffix
          device_command = "test_audio"
        } else {
          command.volume = null
        }

        store.dispatch("executeVehicleCommand", {
          command: device_command,
          vehicleId: this.vehicle.vehicle_id,
          data: {
            volume: command.volume,
          },
        })
      }
    },
    async decommissionDevice() {
      if (this.canProvision) {
        await store.dispatch("DecommissionDevice", this.vehicle.status.device_id)
        this.$router.push({ name: "next-stop-vehicles" })
      }
    },
    async runDiagnostic() {
      if (this.canDiagnose) {
        await store
          .dispatch("RunDiagnostic", { vehicleId: this.vehicle.vehicle_id, command: "levelone_test" })
          .then(result => {
            this.diagnosticLogData = result.data
          })
      }
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async addNote() {
      if (this.canNotes) {
        try {
          const { selectedForeignId, title, comments, selectedPriority, selectedStatus, claimant } = this.formData
          // if fields filled out
          if (
            title.trim() !== "" &&
            comments.trim() !== "" &&
            selectedPriority &&
            selectedStatus !== null &&
            claimant.trim() !== ""
          ) {
            await this.$store.dispatch("addNote", { formData: this.formData })
            store.commit("SET_SNACKBAR", {
              text: `The note ${title} on ${selectedForeignId.title} has been added`,
              color: "success",
            })
            // Reset the form fields after successful submission
            this.formData = {
              selectedType: "Device",
              selectedForeignId: {
                title: this.getDeviceAndVehicleTitle(),
                value: this.device.device_id,
              },
              title: "",
              comments: "",
              selectedPriority: null,
              selectedStatus: null,
              claimant: "",
              assigned: "",
              location: "",
            }
            this.dialog = false
          }
        } catch (error) {
          console.error("error:", error)
          store.commit("SET_SNACKBAR", {
            text: `The note ${this.formData.title} on ${this.formData.selectedForeignId.title} could not be added`,
            color: "error",
          })
        }
      }
    },
    getDeviceAndVehicleTitle() {
      const deviceId = this.device.device_id
      const vehicleId = this.device.config.vehicle_id ? this.device.config.vehicle_id : null

      if (deviceId) {
        return vehicleId ? `Vehicle ${vehicleId} - Device ID: ${deviceId}` : `Device ID: ${deviceId}`
      }

      return "Unknown"
    },
  },
  setup() {
    return {
      icons: {
        mdiVolumeOff,
        mdiVolumeHigh,
      },
    }
  },
}
</script>
