<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <v-row class="mt-1 mb-1" style="align-items: center">
          <v-col cols="5">
            <v-text-field
              id="startDateTime"
              v-model="startDateTime"
              label="Start datetime"
              outlined
              hide-details
              dense
              type="datetime-local"
              :min="lastMonth()"
              :max="today()"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              id="endDateTime"
              v-model="endDateTime"
              label="End datetime"
              outlined
              hide-details
              dense
              type="datetime-local"
              :min="lastMonth()"
              :max="today()"
            />
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" style="min-width: 100%" @click="fetchData">Go</v-btn>
          </v-col>
        </v-row>

        <div v-if="progress.total" class="progress">
          <div class="progress__message">
            <div v-if="progress.finished">Successfully fetched positions!</div>
            <div v-else>
              <div>{{ progress.current }} / {{ progress.total }}...</div>
              <div v-if="dateTimes.startDateTime">{{ dateTimes.startDateTime }} to {{ dateTimes.endDateTime }}...</div>
            </div>
          </div>
          <v-progress-linear v-model="progress.percentage"></v-progress-linear>
        </div>

        <VehicleGPSHistoryMap
          :gps-data="gpsData"
          :data-by-event-id="dataByEventId"
          @update:dataByEventId="dataByEventId = $event"
        />
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-row class="mt-3 mb-1">
          <v-col cols="12">
            <h2 class="mb-2">Events</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="mb-2">Trip Reports</h3>

            <div v-if="tripReportsProgress.total" class="progress">
              <div class="progress__message">
                <div v-if="tripReportsProgress.finished">Successfully fetched trip reports!</div>
                <div v-else>
                  <div>{{ tripReportsProgress.current }} / {{ tripReportsProgress.total }}...</div>
                  <div v-if="startDateTime">{{ startDateTime }} to {{ endDateTime }}...</div>
                </div>
              </div>
              <v-progress-linear v-model="tripReportsProgress.percentage"></v-progress-linear>
            </div>

            <v-data-table
              :headers="[
                {
                  text: 'Trip ID',
                  value: 'trip_id',
                },
                {
                  text: 'Start',
                  value: 'start_datetime',
                },
                {
                  text: 'End',
                  value: 'end_datetime',
                },
                {
                  text: 'View',
                },
              ]"
              :items="tripReports"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.trip_id }}</td>
                  <td>{{ item.start_datetime }}</td>
                  <td>{{ item.end_datetime }}</td>
                  <td>
                    <v-btn icon small @click="() => showTripReportByEvent(item.id)">
                      <v-icon>
                        {{ icons.mdiMapSearch }}
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="mb-2">Other Events</h3>

            <div v-if="eventsProgress.total" class="progress">
              <div class="progress__message">
                <div v-if="eventsProgress.finished">Successfully fetched events!</div>
                <div v-else>
                  <div>{{ eventsProgress.current }} / {{ eventsProgress.total }}...</div>
                  <div v-if="startDateTime">{{ startDateTime }} to {{ endDateTime }}...</div>
                </div>
              </div>
              <v-progress-linear v-model="eventsProgress.percentage"></v-progress-linear>
            </div>

            <v-data-table
              :headers="[
                {
                  text: 'Type',
                  value: 'type',
                  sortable: false,
                },
                {
                  text: 'Trip ID',
                  value: 'trip_id',
                  sortable: false,
                },
                {
                  text: 'Position',
                  sortable: false,
                },
                {
                  text: 'Date/Time',
                  value: 'date_time',
                },
              ]"
              :items="eventsData"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <span v-if="item.type === 'off_route_message'">Off route message</span>
                    <span v-if="item.type === 'trip_assignment'">Trip assignment</span>
                  </td>
                  <td>{{ item.trip_id }}</td>
                  <td>{{ item.position }}</td>
                  <td>{{ item.date_time }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiMapSearch } from "@mdi/js"
import axios from "axios"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import store from "@/store"
import VehicleGPSHistoryMap from "./VehicleGPSHistoryMap.vue"
import urlHelpers from "@/mixins/urlHelpers"
import globalHelpers from "@/mixins/globalHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "DateRangePicker",
  components: {
    VehicleGPSHistoryMap,
  },
  mixins: [urlHelpers, globalHelpers],
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiMapSearch,
      },
    }
  },
  data() {
    return {
      dateMenu: false,
      results: [],
      progress: {},
      eventsProgress: {},
      tripReportsProgress: {},
      dateTimes: {},
      eventsDateTimes: {},
      gpsData: [],
      eventsData: [],
      dataByEventId: [],
      tripReports: [],
      vehicleId: this.vehicle.vehicle_id,
      request: axios.CancelToken.source(),
      eventsRequest: axios.CancelToken.source(),
      tripReportsRequest: axios.CancelToken.source(),
      startDateTime: this.startOfDay(this.today()),
      endDateTime: this.today(),
    }
  },
  mounted() {
    window.addEventListener("routeChanged", this.handleRouteChange)
  },
  methods: {
    startOfDay(date) {
      const startOfDay = this.utcDate(date)
      startOfDay.setUTCHours(0)
      startOfDay.setUTCMinutes(0)
      startOfDay.setUTCSeconds(0)

      return this.formattedDate(startOfDay)
    },
    endOfDay(date) {
      const endOfDay = this.utcDate(date)
      endOfDay.setUTCHours(23)
      endOfDay.setUTCMinutes(59)
      endOfDay.setUTCSeconds(59)

      return this.formattedDate(endOfDay)
    },
    formattedDate(date) {
      return date.toISOString().split(".")[0]
    },
    utcDate(date) {
      return new Date(Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate()))
    },
    daysFromNow(days, date = new Date()) {
      return new Date(
        Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate() + days),
      )
    },
    hoursFromNow(hours, date = new Date()) {
      return new Date(
        Date.UTC(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          new Date(date).getHours() + hours,
          new Date(date).getMinutes(),
        ),
      )
    },

    today() {
      return new Date().toLocaleString("sv").replace(" ", "T")
    },
    yesterday() {
      return this.formattedDate(this.daysFromNow(-1))
    },
    lastMonth() {
      return this.formattedDate(this.daysFromNow(-30))
    },

    handleRouteChange() {
      this.request.cancel("Navigated away, cancelling request")
      this.eventsRequest.cancel("Navigated away, cancelling request")
    },

    padZero(num) {
      return num < 10 ? `0${num}` : num
    },

    getHourlyIntervals(startTime, endTime) {
      const intervals = []
      const start = new Date(startTime)
      const end = new Date(endTime)

      // Loop through each hour between start and end time
      const current = new Date(start)
      while (current <= end) {
        const fixedDate = this.utcDate(new Date(current))
        fixedDate.setUTCHours(current.getHours())
        fixedDate.setUTCMinutes(current.getMinutes())
        intervals.push(this.formattedDate(fixedDate)) // Store a new Date object for each interval
        current.setHours(current.getHours() + 1)
      }

      return intervals
    },

    async fetchData() {
      this.gpsData = []
      this.eventsData = []
      this.tripReports = []

      const [gpsData, eventsData, tripReports] = await Promise.all([
        this.fetchPositionsForSelectedDates(),
        this.fetchEventsForSelectedDates(),
        this.fetchTripReportsForSelectedDates(),
      ])

      this.gpsData = gpsData
      this.eventsData = eventsData
      this.tripReports = tripReports

      this.addToSearchHistory(this.startDateTime, "startDateTime")
      this.addToSearchHistory(this.endDateTime, "endDateTime")
    },

    async showTripReportByEvent(eventId = null) {
      const cancelToken = this.eventsRequest.token
      const data = await store.dispatch(
        "getDeviceEventByEventId",
        {
          vehicle_id: this.vehicleId,
          event_type: "trip_reports",
          start_datetime: this.startDateTime,
          end_datetime: this.endDateTime,
          event_id: eventId,
        },
        cancelToken,
      )

      const updatedData = data?.map(item => {
        const logs = item.event_data.logs.map(log => ({
          ...log,
          timestamp: dayjs.unix(log.timestamp).tz("Pacific/Auckland").format("YYYY-MM-DD HH:mm:ss"),
        }))

        return {
          ...item,
          event_data: {
            ...item.event_data,
            logs,
            start_datetime: dayjs
              .unix(item.event_data.start_timestamp)
              .tz("Pacific/Auckland")
              .format("YYYY-MM-DD HH:mm"),
            end_datetime: dayjs.unix(item.event_data.end_timestamp).tz("Pacific/Auckland").format("YYYY-MM-DD HH:mm"),
          },
        }
      })

      if (updatedData.some(item => item.error)) {
        throw new Error("An error occurred in the data")
      }

      this.dataByEventId = updatedData
    },

    async fetchPositionsForSelectedDates() {
      this.request.cancel("New fetch requested")
      this.request = axios.CancelToken.source()

      const currentRequest = this.request
      const dateTimes = this.getHourlyIntervals(this.startDateTime, this.endDateTime)
      const total = dateTimes.length

      let combinedData = []

      this.progress = {
        total,
        current: 1,
        percentage: 0,
        finished: false,
      }

      for (let i = 0; i < total; i += 1) {
        if (this.request !== currentRequest) {
          console.log("Skipping...")
          // eslint-disable-next-line no-continue
          continue
        }

        this.dateTimes.startDateTime = dateTimes[i]
        this.dateTimes.endDateTime = this.formattedDate(this.hoursFromNow(1, new Date(this.dateTimes.startDateTime)))

        if (this.endDateTime < this.dateTimes.endDateTime) {
          this.dateTimes.endDateTime = this.endDateTime
        }

        try {
          // eslint-disable-next-line no-await-in-loop
          const data = await store.dispatch("getPositions", {
            vehicle_id: this.vehicleId,
            start_datetime: this.dateTimes.startDateTime,
            end_datetime: this.dateTimes.endDateTime,
            cancelToken: this.request.token,
          })

          if (data.error) {
            throw new Error(data.error)
          }

          combinedData = [...combinedData, ...data]
        } catch (e) {
          console.error(e)
        }

        this.progress = {
          total,
          current: i + 1,
          percentage: ((i + 1) / total) * 100,
          finished: i + 1 === total,
        }
      }

      return combinedData
    },

    async fetchEventsForSelectedDates() {
      this.eventsRequest.cancel("New fetch requested")
      this.eventsRequest = axios.CancelToken.source()

      let combinedData = []

      this.eventsProgress = {
        total: 2,
        current: 0,
        percentage: 0,
        finished: false,
      }

      await new Promise(resolve => setTimeout(resolve, 2000))

      try {
        const data = await store.dispatch("getDeviceEventsByVehicleId", {
          vehicle_id: this.vehicleId,
          event_type: "trip_assignments",
          start_datetime: this.startDateTime,
          end_datetime: this.endDateTime,
          cancelToken: this.eventsRequest.token,
        })

        if (data.error) {
          throw new Error(data.error)
        }

        combinedData = [
          ...combinedData,
          ...data.map(i => ({
            type: i.event_type,
            ...i.event_data,
          })),
        ]
      } catch (e) {
        console.error(e)
      }

      this.eventsProgress = {
        total: 2,
        current: 1,
        percentage: 50,
        finished: false,
      }

      await new Promise(resolve => setTimeout(resolve, 2000))

      try {
        const data = await store.dispatch("getDeviceEventsByVehicleId", {
          vehicle_id: this.vehicleId,
          event_type: "off_route_messages",
          start_datetime: this.startDateTime,
          end_datetime: this.endDateTime,
          cancelToken: this.eventsRequest.token,
        })

        if (data.error) {
          throw new Error(data.error)
        }

        combinedData = [
          ...combinedData,
          ...data.map(i => ({
            type: i.event_type,
            ...i.event_data,
          })),
        ]
      } catch (e) {
        console.error(e)
      }

      this.eventsProgress = {
        total: 2,
        current: 2,
        percentage: 100,
        finished: true,
      }

      return combinedData
    },

    async fetchTripReportsForSelectedDates() {
      this.tripReportsRequest.cancel("New fetch requested")
      this.tripReportsRequest = axios.CancelToken.source()

      let combinedData = []

      this.tripReportsProgress = {
        total: 1,
        current: 0,
        percentage: 0,
        finished: false,
      }

      try {
        const data = await store.dispatch("getDeviceEventsByVehicleId", {
          vehicle_id: this.vehicleId,
          event_type: "trip_reports",
          start_datetime: this.startDateTime,
          end_datetime: this.endDateTime,
          cancelToken: this.eventsRequest.token,
        })

        if (data.error) {
          throw new Error(data.error)
        }

        data.forEach(item => {
          item.event_data.start_datetime = dayjs
            .unix(item.event_data.start_timestamp)
            .tz("Pacific/Auckland")
            .format("YYYY-MM-DD HH:mm")
          item.event_data.end_datetime = dayjs
            .unix(item.event_data.end_timestamp)
            .tz("Pacific/Auckland")
            .format("YYYY-MM-DD HH:mm")
        })

        combinedData = [
          ...data.map(i => ({
            type: i.event_type,
            ...i.event_data,
          })),
        ]
      } catch (e) {
        console.error(e)
      }

      this.tripReportsProgress = {
        total: 1,
        current: 1,
        percentage: 100,
        finished: true,
      }

      return combinedData
    },
  },
}
</script>

<style lang="scss" scoped>
.progress {
  margin-bottom: 1rem;

  &__message {
    text-align: center;
    margin: 0.25rem 0;
  }
}

.log-type-color {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
